import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import $ from 'jquery';
import AnimatedCursor from "react-animated-cursor"

//imports
import Header from './includes/Header';
import Footer from './includes/Footer';
import Priloader from './includes/Priloader';

//aminations
import Lenis from 'lenis';

import './App.css';
// import logo from './logo.png'; 

//pages
import Home from './pages/Home';
import Works from './pages/Works';
import Projects from './pages/Projects';
import About from './pages/About';
import Contact from './pages/Contact';

const App = () => {

  const [showCursor, setShowCursor] = useState(true);
  const location = useLocation();

  // Detect screen width and toggle cursor visibility
  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setShowCursor(false); // Hide cursor for mobile screens
    } else {
      setShowCursor(true); // Show cursor for larger screens
    }
  };
  
  const onRouteChange = () => {
    console.log('Route has changed:', location.pathname);
    $(".bg-slider").css({"left":"0vw"})
    setTimeout(() => {
      $(".bg-slider").css({"left":"-100vw"})
    }, 500);
    window.scrollTo(0, 0);
  };

  const onPageLoad = () => {
    console.log('Page has fully loaded');
    // Your custom logic here
    setTimeout(function() {
      $(".preloader").css({"opacity" : "0"})
    }, 1500);
    setTimeout(function() {
      $(".preloader").css({"display" : "none"})
    }, 2000);
  };

  //useEffect first load
  useEffect(() => {

    handleResize();

    // Initialize Lenis with options (smooth scroll)
    const lenis = new Lenis({
      duration: 1.5, // scroll duration (in seconds)
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // easing function
      direction: 'vertical', // vertical, horizontal, or both
      gestureDirection: 'vertical', // vertical, horizontal, or both
      smooth: true, // enable/disable smooth scrolling
      smoothTouch: false, // smooth scrolling for touch devices
      touchMultiplier: 2, // speed multiplier for touch devices
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    if (document.readyState === 'complete') {
      // If the page has already loaded, call the function directly
      onPageLoad();
    } else {
      // If the page hasn't loaded yet, add an event listener for the 'load' event
      window.addEventListener('load', onPageLoad);
    }

    requestAnimationFrame(raf);


    // Cleanup on unmount
    return () => {
      lenis.destroy();
      window.removeEventListener('load', onPageLoad);
      onRouteChange();
    };
    //end of smooth scroll

  }, [location]);

  return (
    <div>
      {showCursor && ( // Render the cursor only if `showCursor` is true
        <AnimatedCursor
          innerSize={10}
          outerSize={60}
          innerScale={1.2}
          outerScale={1.5}
          outerAlpha={0.5}
          hasBlendMode={true}
          innerStyle={{
            backgroundColor: '#2b2b2b',
          }}
          outerStyle={{
            background: '#fff',
            border: '1px solid #2b2b2b',
            mixBlendMode: 'difference',
          }}
        />
      )}
      <div className='preloader'>
        <Priloader />
      </div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/works" element={<Works />} />
        <Route path="/projects/:id" element={<Projects />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
