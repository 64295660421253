import React, { useState, useEffect } from 'react'
import "./textInput.css"

export default function SelectInput({ value, placeholder, setval, opt }) {

  const optHeight = opt.length * 4.3;
  const optHeight2 = opt.length * 3.1;
  const [Width, setWidth] = useState()
  const [Tg, setTg] = useState(false)
  const ToggleEvent = () => {
    setTg(prevState => !prevState)
  };

  function selected(value) {
    setval(value)
    setTg(false)
  }

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div className='inputDiv relative'>
      <input
        className='textInput selectInput'
        type="text"
        value={value}
        placeholder={placeholder}
        onClick={ToggleEvent}
        required
      />
      <div className='optionsInput' style={Width >= 768 ? {
        padding: Tg ? '1.2vw' : '0',
        height: Tg ? `${optHeight}vh` : '0'
      }:{
        padding: Tg ? '10px' : '0',
        height: Tg ? `${optHeight2}vh` : '0'}}>
        {opt.map(option => (
          <div className='optionInput' key={option} onClick={() => selected(option)}>
            {option}
          </div>
        ))}
      </div>
    </div>
  )
}
